import moment from 'moment-timezone'
import { httpHelper } from '@/utils'

const { VUE_APP_COLOR_ACCENT: colorAccent } = process.env

export default {
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            chartData: {},
            sharedChartOptions: {
                chart: {
                    group: 'treemonitoring',
                    type: 'line',
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: true,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
                colors: [
                    colorAccent,
                    '#2E93fA',
                    '#66DA26',
                    '#546E7A',
                    '#E91E63',
                    '#FF9800',
                ],
            },
        }
    },
    computed: {
        temperatureSeries() {
            let series = []
            let dataEntries = Object.entries(this.chartData)
            for (let [key, series_for_depth] of dataEntries) {
                let name = this.$t('temperature') + ' ' + (Number(key) + 1)
                if (Number(key) === 0) {
                    name += ' (' + this.$t('top') + ')'
                } else if (Number(key) === dataEntries.length - 1) {
                    name += ' (' + this.$t('bottom') + ')'
                }

                series.push({
                    name: name,
                    data: series_for_depth.temperature,
                })
            }
            return series
        },
        moistureSeries() {
            let series = []
            let dataEntries = Object.entries(this.chartData)
            for (let [key, series_for_depth] of dataEntries) {
                let name = this.$t('moisture') + ' ' + (Number(key) + 1)
                if (Number(key) === 0) {
                    name += ' (' + this.$t('top') + ')'
                } else if (Number(key) === dataEntries.length - 1) {
                    name += ' (' + this.$t('bottom') + ')'
                }

                series.push({
                    name: name,
                    data: series_for_depth.moisture,
                })
            }
            return series
        },
    },
    methods: {
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.chartData = this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            const results = []
            let url = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&fields=timestamp,sensor_data',
                '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
            ].join('')

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            return results
        },
        addHistoryDataFromResponse(data) {
            let chartData = {}
            for (let datapoint of data) {
                let sensorData = datapoint.sensor_data
                let timestamp = datapoint.timestamp

                for (let key of Object.keys(sensorData)) {
                    let key_num = null
                    let type = null
                    if (key.indexOf('moisture_') === 0) {
                        key_num = key.replace('moisture_', '')
                        type = 'moisture'
                    } else if (key.indexOf('temperature_') === 0) {
                        key_num = key.replace('temperature_', '')
                        type = 'temperature'
                    }

                    if (key_num !== null) {
                        chartData[key_num] = chartData[key_num] || {
                            temperature: [],
                            moisture: [],
                        }
                        chartData[key_num][type].push([
                            timestamp,
                            sensorData[key],
                        ])
                    }
                }
            }
            return chartData
        },
        chartOptionsMoisture(key) {
            let options = {
                ...this.sharedChartOptions,
                ...{
                    yaxis: {
                        title: {
                            text: this.$t('moisture'),
                        },
                        min: 0,
                        max: 100,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                    },
                },
            }
            options.chart.id = key
            return options
        },
        chartOptionsTemperature(key) {
            let options = {
                ...this.sharedChartOptions,
                ...{
                    yaxis: {
                        title: {
                            text: this.$t('temperature') + ' (℃)',
                        },
                        min: -10,
                        max: 40,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                    },
                },
            }
            options.chart.id = key
            return options
        },
    },
}
